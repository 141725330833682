import type { Route } from 'vue-router';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const postMessage = (message: any, targetOrigin: string = '*', transfer?: Transferable[]): void => {
  window?.parent?.postMessage(message, targetOrigin, transfer);
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const notLoggedUserClick = (): void => postMessage({ type: 'signin' });

export const sendRoute = (route: Route): void => {
  postMessage({ type: 'view', value: route.path });
};

export const goToMainDemo = (value: boolean): void => {
  postMessage({ type: 'demo-main', value });
};
