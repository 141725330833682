import Vue from 'vue';
import CompositionAPI from '@vue/composition-api';
import App from './App.vue';
import store from './store';
import VueScreen from 'vue-screen';
import VTooltip from 'v-tooltip';
import VModal from 'vue-js-modal';
import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/dist/v-dropdown-menu.css';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import router from './router/index';
import { i18n } from '@/plugins/i18n';
import '@/plugins/vue-shepherd';

import Clipboard from 'v-clipboard';
// import * as Sentry from '@sentry/vue';
import SlideUpDown from 'vue-slide-up-down';
// fix apexcharts problem for iOS
import { install } from 'resize-observer';
import { defaultLocale } from '@/helpers/localeHelpers';
import NumeralSpaces from '@/filters/numeral-spaces';

import debounce from 'lodash/debounce';
import { postMessage } from '@/helpers/postMessageHelpers';

import { wsClient } from '@/sockets';

import { version } from './version.json';

declare global {
  interface Window {
    ethereum?: any;
    web3?: any;
    ga?: any;
    gtag?: any;
    dataLayer?: any;
    theme?: string;
    opera?: string;
    define?: any;
    THEOplayer?: any;
    mountDate?: number;
    lobbyInit?: boolean;
    TP_VERSION?: string;
    webkitAudioContext?: typeof AudioContext;
  }
}

window.TP_VERSION = version;

const handlePopup = debounce((value: boolean): void => {
  postMessage({ type: 'ui_popup', value });
}, 500);

Vue.use(CompositionAPI);
Vue.use(VTooltip);
Vue.use(VModal);
Vue.use(DropdownMenu);
Vue.use(vueNumeralFilterInstaller, { locale: defaultLocale });
Vue.use(Clipboard);
Vue.component('slide-up-down', SlideUpDown);
Vue.filter('numeralSpaces', NumeralSpaces);

Vue.use(VueScreen, {
  mc: 361,
  xs: 481,
  sm: 769,
  md: 1025,
  lg: 1201,
  xl: 1681
});

Vue.config.productionTip = false;

Vue.prototype.$modal = new Proxy(Vue.prototype.$modal, {
  get(target, prop, receiver) {
    if (typeof prop === 'string' && ['show', 'hide'].includes(prop)) {
      handlePopup(prop === 'show');
    }

    return Reflect.get(target, prop, receiver);
  }
});

const init = async (): Promise<void> => {
  try {
    new Vue({
      store,
      router,
      i18n,
      render: (h) => h(App),
      mounted(): void {
        wsClient.connect();
      }
    }).$mount('#widget');
  } catch (e) {
    console.log(e);
  }
};

init();
install();

// const sentryDsn: string = typeof process.env.VUE_APP_SENTRY_DSN === 'string' ? process.env.VUE_APP_SENTRY_DSN : '';
//
// if (!!sentryDsn && !window.location.hostname.includes('translate.goog')) {
//   Sentry.init({
//     Vue,
//     dsn: sentryDsn,
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: [process.env.VUE_APP_API_URL, process.env.VUE_APP_PG_URL, /^\//]
//       })
//     ],
//     environment: process.env.VUE_APP_CI_CD_ENV || 'dev',
//     tracesSampleRate: 0,
//     release: `widget-${process.env.VUE_APP_CI_COMMIT_REF_SLUG}-${process.env.VUE_APP_CI_COMMIT_SHA}`,
//     beforeSend: (event) => {
//       if (window.location.hostname === 'localhost') {
//         return null;
//       }
//       const error = event?.exception?.values?.[0];
//
//       const filename = error?.stacktrace?.frames?.[0]?.filename || '';
//       const message = error?.value || '';
//
//       // ignore libraries errors
//       if (/chunk-vendors/.test(filename)) {
//         return null;
//       }
//
//       const ignoreData = {
//         apexcharts: [
//           /InvalidStateError: The object is in an invalid state/,
//           /Cannot read property 'classList' of null/,
//           /Cannot read properties of null \(reading 'classList'\)/, // apexcharts source code errors during hover
//           /Cannot read properties of null \(reading 'getBoundingClientRect'\)/, // same
//           /null is not an object \(evaluating '\w\.gridRect\.getBoundingClientRect'\)/, // same
//           /null is not an object \(evaluating '\w\.target\.parentNode\.classList'\)/,
//           /null has no properties/,
//           /Cannot read property 'querySelector' of null/
//         ],
//         'vue-screen': [/_query\.addListener is not a function/], // lib internal error (unsolvable)
//         'script.hotjar.com': [/Cannot read property 'origin' of undefined/] // error in 3rd party lib
//       };
//
//       for (const namePart in ignoreData) {
//         const ignore = ignoreData[namePart];
//
//         if (filename.includes(namePart)) {
//           if (ignore.some((txt) => txt.test(message))) {
//             return null;
//           }
//         }
//       }
//
//       // ignore 3rd party scripts errors
//       if (filename === `<anonymous>`) {
//         return null;
//       }
//
//       return event;
//     },
//     ignoreErrors: [
//       /Request failed with status code .*/,
//       /Network Error/,
//       /AbortError/,
//       /AbortError: The operation was aborted/,
//       /Request aborted/,
//       /Avoided redundant navigation to current location:/,
//       /Redirected when going from ".*?" to ".*?" via a navigation guard/,
//       /Navigation cancelled from ".*?/,
//       /timeout( of (.*?)ms)? exceeded/, // network error
//       /Loading chunk (.*?) failed/, // network error
//       /Loading CSS chunk (.*?) failed/, // network error
//       'modules[moduleId] is undefined', // network error
//       "undefined is not an object (evaluating 'webkit.messageHandlers.contextMenuMessageHandler.postMessage')", // browser error case (firefox iOS)
//       "Cannot assign to read only property 'solana' of object ", // browser extension error case (Mathwallet)
//       /can't redefine non-configurable property "(ethereum|solana)"/,
//       'Attempted to assign to readonly property', // browser/extension error case
//       'Unexpected token .', //jwt_decode exception
//       /Blocked a frame with origin "(.*?)" from accessing a cross-origin frame/, // cross-origin iframe restrictions
//       /Fullscreen request denied/, // error due browser settings
//       /Non-Error promise rejection captured/, // AxiosErrors
//       /NotAllowedError: Document is not focused/,
//       /^NotAllowedError:.*(play method|play).*/, // autoplay permissions
//       /Unexpected EOF/, // parser of safari error
//       /close is not a function/, //sockjs error
//       /Illegal invocation/, // sockjs
//       /Failed to read the 'localStorage' property from 'Window'/, // browsers settings
//       /Missing or invalid topic field/, // walletconenct unhandle error
//       /Unterminated regular expression literal/, // vue-mask error in safari
//       /webkit-masked-url/,
//       /onunhandledrejection/, //web-3 browser extention.
//       /sendObjectBeacon/,
//       '__gCrWeb'
//     ],
//     denyUrls: [
//       /^moz-extension:\/\//i,
//       /^chrome:\/\//i,
//       /^chrome-extension:\/\//i,
//       /safari-(web-)?extension:/,
//       /apexcharts/,
//       /video-js/,
//       /beacon.min.js/,
//       /theo.live/,
//       /script.hotjar.com/,
//       /webkit-masked-url/
//     ] // ignore extensions errors
//   });
//
//   Sentry.configureScope(function (scope) {
//     scope.setTag('Project', 'widget');
//   });
// }
