import humanizeDuration from 'humanize-duration';

export const defaultLocale: string = 'en';

export const LangsArray = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Spanish', value: 'es' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Russian', value: 'ru' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Czech', value: 'cs' },
  { label: 'Creole', value: 'cr' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Slovakian', value: 'sk' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Kazakh', value: 'kz' },
  { label: 'Uzbek', value: 'uz' }
];

export function getHumanLocale(locale: string): string {
  return LangsArray.find((item) => item.value === locale)?.label || locale;
}

/**
 * Get local translations for locale.
 * @param locale
 * @return Promise with translations in i18n.
 */
export async function getLocalTranslation(locale: string): Promise<any> {
  return await import(/* webpackMode: "lazy" */ `../../public/static/i18n/${locale}.json`);
}

/**
 * Humanize duration with preset language fallback
 * @param {number} ms - value on milliseconds
 * @param {humanizeDuration.Options} options - current language or config for service
 */
export const formatDuration = humanizeDuration.humanizer({
  fallbacks: ['en'],
  languages: {
    cr: {
      y: () => 'ane',
      mo: () => 'mwa',
      w: () => 'semèn',
      d: () => 'jou',
      h: () => 'èdtan',
      m: () => 'minit',
      s: () => 'segond',
      ms: () => 'milisegond'
    }
  }
});
