import retry from 'axios-retry';
import store from '@/store';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { token, forceProdApi } from '@/api/helpers/urlParams';
import { API_CLIENT_URL, parentDomain } from '@/helpers/urlHelpers';

const BASE_HEADERS = {};

if (token) {
  BASE_HEADERS['X-AUTH-TOKEN'] = `Bearer ${token}`;
}

if (parentDomain) {
  BASE_HEADERS['X-REFERER'] = parentDomain;
}

/**
 * Http for connection to the API.
 */
export const http: AxiosInstance = axios.create({
  baseURL: forceProdApi ? 'https://api.trueplay.io/' : API_CLIENT_URL,
  headers: BASE_HEADERS
});

http.interceptors?.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 500 && (data as any)?.path === '/api/v1/user/finance/operator-balance') {
        store.commit('layout/setOperatorBalanceError', true);
      }

      if (status === 403 && (data as any)?.message === 'Widget is disabled') {
        store.commit('layout/setWidgetStatus', true);
      }
    }

    return Promise.reject(error);
  }
);

retry(http, {
  retryDelay: (count: number): number => count * 1000,
  retries: 2
});
