import { http } from './Connect';

import type { AxiosResponse } from 'axios';

import {
  TStreamSubscription,
  TStreamSubscriptionPayload,
  TStreamWalletBalance,
  TStreamSettings,
  TStreamer,
  TStreamBroadcastStatus,
  EStreamStatus
} from '@/api/schema';

/**
 * Streaming settings
 */
export class StreamBroadcast {
  static start(): Promise<void> {
    return http.patch('/api/v1/copystake/broadcast/start');
  }

  static stop(): Promise<void> {
    return http.patch('/api/v1/copystake/broadcast/stop');
  }

  static getInfo(): Promise<TStreamer> {
    return getStreamerInfo();
  }

  static getStatus(): Promise<EStreamStatus> {
    return http
      .get('/api/v1/copystake/broadcast/status')
      .then(({ data }: AxiosResponse<TStreamBroadcastStatus>) => data?.status);
  }
}

export const getStreamerInfo = (): Promise<TStreamer> =>
  http
    .get(`api/v1/copystake/streamer/info`)
    .then(({ data }: AxiosResponse<TStreamer>) => data)
    .catch(() => {
      return Promise.resolve(null);
    });

export const getStreamBalance = (): Promise<TStreamWalletBalance> =>
  http.get('/api/v1/copystake/balance').then(({ data }: AxiosResponse) => data);

export const getStreamSettings = (): Promise<TStreamSettings> =>
  http.get('/api/v1/copystake/settings').then(({ data }: AxiosResponse<TStreamSettings>) => data);

export const getCurrentSubscription = (): Promise<TStreamSubscription> =>
  http.get('/api/v1/copystake/subscription').then(({ data }: AxiosResponse) => data);

export const createStreamSubscription = async (
  payload: TStreamSubscriptionPayload,
  wallet?: string,
  id?: number
): Promise<TStreamSubscription> => {
  const query = wallet ? `player_wallet=${wallet}` : `player_id=${id}`;

  return http.post(`/api/v1/copystake/subscription?${query}`, payload).then(({ data }: AxiosResponse) => data);
};

export const updateStreamSubscription = (
  payload: TStreamSubscriptionPayload,
  id: number
): Promise<TStreamSubscription> =>
  http.put(`/api/v1/copystake/subscription?player_id=${id}`, payload).then(({ data }: AxiosResponse) => data);

export const stopStreamSubscription = (id: number): Promise<void> =>
  http.delete(`/api/v1/copystake/subscription?player_id=${id}`);

export const resumeCurrentSubscription = (id: number): Promise<TStreamSubscription> =>
  http.patch('/api/v1/copystake/subscription/resume', null, { params: { id } }).then(({ data }: AxiosResponse) => data);

const modifyTx = (item: any) => ({
  createdAt: item.date,
  id: item.id,
  uuid: item.hash,
  type: item.actionType?.replace('_COPIED', ''),
  attributes: {
    GA_UUID: item.hash,
    AMOUNT: item.amount,
    CURRENCY: item.currency,
    GAME_NAME: item.gameName
  }
});

const middleware = ({ data }: AxiosResponse): any => ({
  transactions: data.gameActions.map(modifyTx),
  hasNext: data.hasNext
});

export const getStreamerHistory = (streamerWalletHash: string, from?: string): Promise<any> =>
  http
    .get('api/v1/copystake/streamer/game-actions', {
      params: {
        from,
        streamerWalletHash,
        size: 11
      }
    })
    .then(middleware);

export const getUserHistory = (_: string, from?: string): Promise<any> =>
  http
    .get('api/v1/copystake/user/game-actions', {
      params: {
        from,
        size: 11
      }
    })
    .then(middleware);

export const poolStreamTransactionHistory = (wallet: string, createdAt?: string, isStreamer = true): Promise<any> => {
  const method = isStreamer ? getStreamerHistory : getUserHistory;

  return method(wallet, createdAt);
};

export const getStreamFinanceHistory = (_: string, createdAt?: string): Promise<any> => {
  return http
    .get('/api/v1/copystake/user/transactions', { params: { from: createdAt, size: 6 } })
    .then(({ data }: AxiosResponse) => ({
      hasNext: !data.last,
      transactions: data.content.map((item: any) => ({
        amount: item?.amount,
        currency: item?.currency,
        createdAt: item?.date,
        type: item?.type,
        hashId: item?.date,
        status: 'COMPLETED'
      }))
    }));
};

export const getStreamId = (): Promise<string> => {
  return http.post('/api/v1/copystake/streamer/streamId').then(({ data }: AxiosResponse) => data?.streamId);
};
