const GET_DEFAULT_SCROLL_OPTIONS: () => ScrollToOptions = () => ({
  top: 0,
  left: 0,
  behavior: 'smooth'
});
const DEFAULT_SCROLL_CONTAINER_ID: string = 'widget-layout';

export const getScrollContainerElement = (): HTMLElement | null => document.getElementById(DEFAULT_SCROLL_CONTAINER_ID);

// scroll element to the top or center of the screen
export const scrollToElement = (el: HTMLElement, scrollToCenter: boolean = false): void => {
  const scrollContainerElement = getScrollContainerElement();

  if (!scrollContainerElement || !el) return;

  const { clientHeight, scrollTop } = scrollContainerElement;
  const { top, height } = el.getBoundingClientRect();

  const topOffset = scrollToCenter ? (clientHeight - height) / 2 : 0;

  scrollContainerElement.scroll({
    top: top - topOffset + scrollTop,
    left: 0,
    behavior: 'smooth'
  });
};

// scroll to top
export const scrollToTop = (): void => {
  const scrollContainerElement = getScrollContainerElement();
  if (!scrollContainerElement) return;

  scrollContainerElement.scroll(GET_DEFAULT_SCROLL_OPTIONS());
};

// scroll to coordinates
export const scrollToPosition = ({
  top = GET_DEFAULT_SCROLL_OPTIONS().top,
  left = GET_DEFAULT_SCROLL_OPTIONS().left,
  behavior = GET_DEFAULT_SCROLL_OPTIONS().behavior
}: ScrollToOptions = {}): void => {
  const scrollContainerElement = getScrollContainerElement();
  if (!scrollContainerElement) return;

  scrollContainerElement.scroll({
    top,
    left,
    behavior
  });
};
