import jwt_decode from 'jwt-decode';
import { token } from '@/api/helpers/urlParams';
import { Role } from '@/helpers/roleHelper';

export interface TokenData {
  role: string;
  id_user: number;
  id_operator: number;
  exp: number;
}

export const validateToken = (token?: string): string => {
  if (getMsToTokenExpire(token)) {
    return token;
  }

  return null;
};

export const parsedToken = (token?: string): TokenData => jwt_decode(token);

export const getMsToTokenExpire = (targetToken: string = token): number => {
  try {
    const now = new Date().getTime();
    const { exp }: TokenData = parsedToken(targetToken);

    return Math.max(exp * 1e3 - now, 0);
  } catch (err) {
    return 0;
  }
};

export const isAuthorized = (targetToken: string = token): boolean => {
  try {
    const { role } = parsedToken(targetToken);

    return role === Role.User && !!Math.max(getMsToTokenExpire(targetToken) - 5000, 0);
  } catch (error) {
    return false;
  }
};

export const getUserId = (targetToken: string = token): number => {
  if (!isAuthorized(targetToken)) return;

  const { id_user }: TokenData = parsedToken(targetToken);

  return id_user;
};

export const getOperatorId = (): number => {
  const { id_operator } = parsedToken(token);

  return id_operator;
};

export const isValidToken = (targetToken: string = token) => {
  try {
    const { role } = parsedToken(targetToken);

    return [Role.Anonimus, Role.User].includes(role) && !!Math.max(getMsToTokenExpire(targetToken) - 5000, 0);
  } catch (e) {
    return false;
  }
};
