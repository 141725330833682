export const TOKEN_KEY = 'token';
export const EXPIRED_KEY = 'streamExpired';
const url = new URL(location.href);
const search = new URLSearchParams(window.location.search);

const queryToken = url.searchParams.get(TOKEN_KEY);

if (!url.host.includes('localhost')) {
  url.searchParams.delete(TOKEN_KEY);
  window.history.pushState({}, document.title, url.toString());
}

const getAuthToken = (): string => {
  return queryToken;
};

export const forceProdApi = search.get('prodApi') === 'true';

export const token = getAuthToken();

export const isSocketsDebugEnabled = search.get('socketDebug') === 'true';
export const isDebugEnabled = search.has('debug');

const timeout = +search.get('streamTimeout');
export const streamTimeout: number | undefined = isNaN(timeout) ? undefined : timeout * 60 * 1000;
