






import Layout from '@/components/layout/Layout.vue';
import { isDebugEnabled } from '@/api/helpers/urlParams';

const isDev = process.env.NODE_ENV === 'development';
const DebugLink = (): Promise<any> => import(/* webpackMode: "lazy" */ '@/components/base/BaseDebugLink.vue');
export default {
  name: 'App',
  components: {
    Layout,
    DebugLink
  },
  data(): any {
    return {
      isDebugEnabled: isDebugEnabled || isDev
    };
  }
};
